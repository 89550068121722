import { Routes } from '@angular/router';

import { UserPermission } from '@dartsales/common/core/enums/user-permission';
import { AuthorizedGuard } from '@dartsales/common/core/guards/authorized.guard';
import { PermissionsGuard } from '@dartsales/common/core/guards/permissions.guard';
import { SalesAppGuard } from '@dartsales/common/core/guards/sales-app.guard';
import { UnauthorizedGuard } from '@dartsales/common/core/guards/unauthorized.guard';

import { provideReadonlyProjectPermission } from './features/shared/components/layouts/project-layout/project-layout.providers';
import { webRoutePaths } from './web-route-paths';
import { provideRegularProjectType, provideTemplateProjectType } from './features/shared/components/layouts/project-layout/project-type.providers';

const projectChildPaths = webRoutePaths.project.children({ projectId: '' });

const rootChildRoutes: Routes = [
  {
    path: webRoutePaths.welcome.path,
    loadComponent: () => import('./features/shared/components/layouts/welcome-layout/welcome-layout.component')
      .then(m => m.WelcomeLayoutComponent),
    loadChildren: () => import('./features/welcome/welcome.routes').then(m => m.welcomeRoutes),
    providers: [provideRegularProjectType()],
  },
  {
    path: webRoutePaths.project.path,
    loadComponent: () => import('./features/shared/components/layouts/project-layout/project-layout.component')
      .then(m => m.ProjectLayoutComponent),
    data: { breadcrumb: { skip: true } },
    loadChildren: () => welcomeProjectChildRoutes,
    providers: [provideRegularProjectType()],
  },
  {
    path: webRoutePaths.template.path,
    loadComponent: () => import('./features/shared/components/layouts/project-layout/project-layout.component')
      .then(m => m.ProjectLayoutComponent),
    data: { breadcrumb: { skip: true } },
    loadChildren: () => welcomeProjectChildRoutes,
    providers: [provideTemplateProjectType()],
  },
  { path: '', redirectTo: webRoutePaths.welcome.path, pathMatch: 'full' },
];

const welcomeProjectChildRoutes: Routes = [
  { path: '', redirectTo: projectChildPaths.dashboard.path, pathMatch: 'full' },
  {
    path: projectChildPaths.dashboard.path,
    loadChildren: () => import('./features/project-dashboard/project-dashboard.routes')
      .then(m => m.projectDashboardRoutes),
  },
  {
    path: projectChildPaths.proposals.path,
    loadChildren: () => import('./features/project-proposals/project-proposals.routes')
      .then(m => m.projectProposalsRoutes),
  },
  {
    path: projectChildPaths.compare.path,
    loadChildren: () => import('./features/project-compare/project-compare.routes')
      .then(m => m.projectCompareRoutes),
  },
  {
    path: projectChildPaths.files.path,
    loadChildren: () => import('./features/project-files/project-files.routes')
      .then(m => m.projectFilesRoutes),
    providers: [provideReadonlyProjectPermission()],
  },
  {
    path: projectChildPaths.resources.path,
    loadChildren: () => import('./features/project-resources/project-resources.routes')
      .then(m => m.projectResourcesRoutes),
    canActivate: [PermissionsGuard.canActivate],
    data: {
      permissionType: UserPermission.CRUDResource,
    },
  },
  { path: '**', redirectTo: projectChildPaths.dashboard.path },
];

/** App routes. */
export const appRoutes: Routes = [
  {
    path: '',
    canActivate: [UnauthorizedGuard.canActivate, SalesAppGuard.canActivate],
    loadComponent: () => import('./features/shared/components/layouts/app-layout/app-layout.component').then(m => m.AppLayoutComponent),
    loadChildren: () => rootChildRoutes,
  },
  {
    path: webRoutePaths.auth.path,
    canActivate: [AuthorizedGuard.canActivate],
    loadChildren: () => import('./features/auth/auth.routes').then(m => m.authRoutes),
  },
  {
    path: webRoutePaths.accessError.path,
    loadComponent: () => import('./features/shared/components/app-access-error-page/app-access-error-page.component')
      .then(m => m.AppAccessErrorPageComponent),
  },
  {
    path: webRoutePaths.permissionError.path,
    loadComponent: () => import('./features/shared/components/app-permission-error-page/app-permission-error-page.component')
      .then(m => m.AppPermissionErrorPageComponent),
  },
  { path: '**', redirectTo: webRoutePaths.welcome.path },
];
