import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { Observable, map, first } from 'rxjs';

import { injectWebAppRoutes } from 'projects/web/src/app/web-route-paths';

import { UserProfile } from '../models/user/user-profile';
import { CurrentUserService } from '../services/current-user.service';
import { ALLOWED_ROLES } from '../utils/constants';

export namespace SalesAppGuard {

  /** Guard prevents a current user from accessing Sales if he doesn't have necessary permissions. */
  @Injectable({ providedIn: 'root' })
  class SalesAppGuardService {
    private readonly currentUserService = inject(CurrentUserService);

    private readonly router = inject(Router);

    private readonly routePaths = injectWebAppRoutes();

    /** Determine if route could be matched. */
    public canMatch(): Observable<boolean | UrlTree> {
      return this.canNavigate();
    }

    /** Determine if route could be achieved. */
    public canActivate(): Observable<boolean | UrlTree> {
      return this.canNavigate();
    }

    private canNavigate(): Observable<boolean | UrlTree> {
      return this.currentUserService.currentUser$.pipe(
        map(user => this.guard(user)),
        first(),
      );
    }

    private guard(user: UserProfile | null): boolean | UrlTree {
      if (user !== null && (user.isGlobalAdmin === true || this.checkUserHasAllowedRole(user))) {
        return true;
      }
      return this.router.createUrlTree([this.routePaths.accessError.url]);
    }

    private checkUserHasAllowedRole(user: UserProfile): boolean {
      return user.organizations.some(org => org.hasAllowedRoles(ALLOWED_ROLES));
    }
  }

  /** Can activate. */
  export const canActivate: CanActivateFn = () => inject(SalesAppGuardService).canActivate();
}
