import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterOutlet } from '@angular/router';

import { IconsService } from '@dartsales/common/core/services/icons.service';
import { SnackbarNotificationsService } from '@dartsales/common/core/services/snackbar-notifications.service';

/** Root component. */
@UntilDestroy()
@Component({
  selector: 'dartsalesw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {

  private readonly snackbarNotificationsService = inject(SnackbarNotificationsService);

  private readonly iconsService = inject(IconsService);

  public constructor() {
    this.iconsService.registerIcons();
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.subscribeToNotification();
  }

  private subscribeToNotification(): void {
    this.snackbarNotificationsService.notification$.pipe(
      untilDestroyed(this),
    ).subscribe();
  }
}
